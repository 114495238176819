/**
 * Don't use this component as icon, use TagSvgIcon component instead. Example: <TAGSvgIcon icon="aspen_office_location" size=32 />
 */
const AspenOfficeLocation = () => (
  <>
    <g clipPath="url(#clip0_5_1644)">
      <path
        d="M27.8793 13.7345C27.8812 11.7589 27.3931 9.81363 26.4588 8.07289C25.5246 6.33215 24.1732 4.85024 22.5258 3.75982C20.8783 2.6694 18.9862 2.0045 17.0188 1.82464C15.0514 1.64479 13.0701 1.95558 11.2523 2.72921C9.43441 3.50284 7.83676 4.71516 6.60233 6.25764C5.36791 7.80012 4.53522 9.62463 4.17882 11.5678C3.82242 13.511 3.95342 15.5123 4.56011 17.3924C5.16679 19.2726 6.23022 20.973 7.6552 22.3414V22.3414L9.62072 24.3379L15.1966 29.9655C15.3904 30.1582 15.6526 30.2663 15.9259 30.2663C16.1992 30.2663 16.4614 30.1582 16.6552 29.9655V29.9655L22.2207 24.4L24.2069 22.4138C25.376 21.292 26.3045 19.944 26.9358 18.4519C27.5672 16.9597 27.8882 15.3547 27.8793 13.7345V13.7345Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0565 2.26914C12.9504 1.46314 15.0146 1.13934 17.0643 1.32672C19.1141 1.5141 21.0854 2.20683 22.8017 3.34288C24.5181 4.47893 25.926 6.02285 26.8994 7.83643C27.8725 9.64955 28.381 11.6756 28.3793 13.7334C28.3884 15.4209 28.0539 17.0926 27.3963 18.6467C26.7392 20.1997 25.7731 21.6029 24.5569 22.771L17.0088 30.3191L16.655 29.9653L17.0077 30.3201C16.7202 30.6059 16.3313 30.7663 15.9259 30.7663C15.5205 30.7663 15.1316 30.6059 14.8441 30.3201L14.8414 30.3174L9.26553 24.6899L9.2644 24.6887L7.30377 22.6971C5.82173 21.2723 4.71561 19.5026 4.08427 17.546C3.4522 15.5872 3.31571 13.5022 3.68702 11.4776C4.05834 9.45311 4.92587 7.55226 6.21195 5.94523C7.49804 4.3382 9.16254 3.07515 11.0565 2.26914ZM7.6552 22.3414L8.01151 21.9906L9.9759 23.986L9.97703 23.9872L15.5491 29.6109C15.5495 29.6113 15.5498 29.6117 15.5502 29.6121C15.6503 29.7109 15.7852 29.7663 15.9259 29.7663C16.0668 29.7663 16.2021 29.7107 16.3022 29.6114C16.3024 29.6113 16.302 29.6116 16.3022 29.6114L23.8607 22.0529C24.9808 20.9782 25.8705 19.6867 26.4754 18.257C27.0803 16.8274 27.3879 15.2896 27.3793 13.7372L27.8793 13.7345L27.3793 13.734C27.3811 11.8411 26.9135 9.97723 26.0183 8.30934C25.1231 6.64144 23.8283 5.22155 22.2498 4.17676C20.6713 3.13197 18.8584 2.4949 16.9733 2.32257C15.0882 2.15024 13.1898 2.44803 11.448 3.18928C9.70627 3.93054 8.17548 5.09213 6.99271 6.57006C5.80994 8.04799 5.0121 9.79615 4.67062 11.658C4.32913 13.5199 4.45465 15.4374 5.03595 17.2389C5.61724 19.0404 6.63617 20.6696 8.00152 21.9807L7.6552 22.3414Z"
        fill="#002D55"
      />
      <path
        d="M18.0104 10.6L16.6242 7.04138C16.5694 6.9022 16.474 6.78272 16.3504 6.69851C16.2268 6.61431 16.0807 6.56927 15.9311 6.56927C15.7815 6.56927 15.6354 6.61431 15.5118 6.69851C15.3881 6.78272 15.2927 6.9022 15.238 7.04138L14.2035 9.65862H14.4311C15.6868 9.6473 16.9227 9.97235 18.0104 10.6V10.6Z"
        fill="#002D55"
      />
      <path
        d="M21.1344 18.5862L19.3241 13.9517C18.9328 12.9604 18.2515 12.1102 17.3693 11.5122C16.4871 10.9143 15.445 10.5963 14.3793 10.6H13.8413L10.7275 18.5862C10.6992 18.6629 10.6898 18.7453 10.7001 18.8264C10.7105 18.9075 10.7403 18.9848 10.7871 19.0519C10.8338 19.119 10.8961 19.1738 10.9686 19.2115C11.0411 19.2493 11.1216 19.269 11.2034 19.269H12.6724C12.7614 19.2692 12.8484 19.2426 12.9221 19.1928C12.9959 19.143 13.053 19.0723 13.0861 18.9897L13.7482 17.3345C13.7676 17.2819 13.803 17.2366 13.8493 17.2051C13.8957 17.1736 13.9508 17.1573 14.0068 17.1586H17.8551C17.9112 17.1573 17.9662 17.1736 18.0126 17.2051C18.059 17.2366 18.0943 17.2819 18.1137 17.3345L18.7758 18.9897C18.8089 19.0723 18.8661 19.143 18.9398 19.1928C19.0136 19.2426 19.1006 19.2692 19.1896 19.269H20.6586C20.7403 19.269 20.8209 19.2493 20.8934 19.2115C20.9659 19.1738 21.0281 19.119 21.0749 19.0519C21.1216 18.9848 21.1514 18.9075 21.1618 18.8264C21.1722 18.7453 21.1628 18.6629 21.1344 18.5862V18.5862ZM16.9965 15.2862H14.9275C14.8909 15.2854 14.8551 15.2758 14.823 15.2581C14.7909 15.2405 14.7636 15.2154 14.7433 15.1849C14.7229 15.1545 14.7103 15.1196 14.7064 15.0832C14.7024 15.0468 14.7073 15.01 14.7206 14.9759L15.6413 12.6586C15.6653 12.5929 15.709 12.5361 15.7663 12.4959C15.8237 12.4558 15.892 12.4343 15.962 12.4343C16.032 12.4343 16.1003 12.4558 16.1577 12.4959C16.2151 12.5361 16.2587 12.5929 16.2827 12.6586L17.2034 14.9759C17.218 15.0125 17.2228 15.0523 17.2174 15.0913C17.212 15.1304 17.1966 15.1674 17.1726 15.1987C17.1486 15.2299 17.1169 15.2545 17.0806 15.2698C17.0443 15.2852 17.0046 15.2908 16.9655 15.2862H16.9965Z"
        fill="#002D55"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_1644">
        <rect width="25.8621" height="30" fill="white" transform="translate(3 1)" />
      </clipPath>
    </defs>
  </>
)
export default AspenOfficeLocation
